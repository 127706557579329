import { Environment } from '../app/_core/constants/Environments';

export const environment = {
  production: false,
  name: Environment.DEV,
  url: 'https://dev.groups.faithdrivenentrepreneur.org',
  server: 'https://dev.groups.faithdrivenentrepreneur.org/api',
  // server: 'http://192.168.1.171:9000/api',
  authServer: 'https://dev.groups.faithdrivenentrepreneur.org/api-auth',
  mattermostUrl: 'https://dev.mattermost.faithdriveninvestor.org',
  placesApiKey: 'AIzaSyAP_QSIbrgr7186CebjAOsFmgr9mQ45o7o',
  recaptchaApiKey: '6LcmW9oaAAAAAH7CduvrhqLxMmtGeRC7JHR9TQLr',
  firebaseConfig: {
    apiKey: 'AIzaSyBJOOYxn5E5gZLhBR4Hm_ncv2XzMXvTKpQ',
    authDomain: 'faith-driven-dev.firebaseapp.com',
    projectId: 'faith-driven-dev',
    storageBucket: 'faith-driven-dev.appspot.com',
    messagingSenderId: '738069787015',
    appId: '1:738069787015:web:bc69e132c19ecff7eca322',
    measurementId: 'G-592L5CY7J2',
  },
  faithDrivenInvestorUuid: '8afb4f1a-553c-41b6-a136-89adadf44222',
  faithDrivenEntrepreneurUuid: '51b84b9b-7d3b-426a-b978-d6ea2752dfb0',
  faithDrivenEntrepreneurStudyUuid: '8afb4f1a-553c-41b6-a136-89adadf44222',
  completeProfileEntrepreneurUrl: 'https://faithdriven.typeform.com/to/Kotqms2e#email=',
  completeProfileEntrepreneur2Url: 'https://faithdriven.typeform.com/to/v6DzCFt6#email=',
  completeProfileAccreditedUrl: 'https://faithdriven.typeform.com/test-accinvest#email=',
  completeProfileFundManagerUrl: 'https://faithdriven.typeform.com/to/EXIV8uig#email=',
  completeProfileFinancialAdvisorUrl: 'https://faithdriven.typeform.com/to/z35GG5dL#email=',
  completeProfileMinistryLeaderUrl: 'https://faithdriven.typeform.com/to/F9pZ4QAB#email=',
  completeProfileRetailUrl: 'https://faithdriven.typeform.com/to/pv4ANPeR#email=',
  completeProfileQualifiedPurchaserUrl: 'https://faithdriven.typeform.com/test-qp#email=',
};
